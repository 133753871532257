<template>
  <div class="recover">
    <div :style="{ transform: `translateX(-${amendCode.offset}%)` }">
      <a-form
        name="custom-validation"
        ref="formRef"
        :model="formState"
        :rules="rules"
        v-bind="layout"
        :labelCol="{span: 6, offset: 0}"
        @finish="handleFinish"
        @finishFailed="handleFinishFailed"
      >
        <a-form-item has-feedback label="手机号" name="mobile">
          <a-input v-model:value="formState.mobile" :disabled="amendId" />
        </a-form-item>
        <a-form-item has-feedback label="验证码" name="code">
          <a-input-group >
            <a-row :gutter="24">
              <a-col :span="16">
                <a-input v-model:value="formState.code" size="default"/>
              </a-col>
              <a-col :span="8">
                <a-button :disabled="amendCode.flag?true:amendCode.numFlag?true:false" type="primary" @click="checkCode" block>{{amendCode.numFlag?amendCode.num:'发送'}}</a-button>
              </a-col>
            </a-row>
          </a-input-group>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 10, offset: 7 }" style="text-align:center">
          <a-button v-if="!amendId" @click="$router.push({name:'login'})" style="margin-right:10px" >返回登录</a-button>
          <a-button type="primary" html-type="submit">下一步</a-button>
        </a-form-item>
      </a-form>
      <a-form
        name="custom-validation"
        ref="formRef1"
        :model="formState"
        :rules="rules"
        v-bind="layout"
        :labelCol="{span: 6, offset: 0}"
        @finish="handleFinish1"
        @finishFailed="handleFinishFailed"
      >
        <a-form-item has-feedback label="密码" name="user_pwd">
          <a-input
            v-model:value="formState.user_pwd"
            type="password"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item has-feedback label="确认密码" name="checkPass">
          <a-input
            v-model:value="formState.checkPass"
            type="password"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 10, offset: 7 }" style="text-align:center">
          <a-button @click="lastStep" >上一步</a-button>
          <a-button style="margin-left:10px" type="primary" html-type="submit">确定</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
import { message } from 'ant-design-vue'
import { defineComponent, getCurrentInstance, onMounted, reactive, ref, watch } from 'vue'
import { ResetPass, regResetCode, register } from '../../api'
export default defineComponent({
  props: {
    id: Number,
    closeFun: Function,
    mobile: Number
  },
  setup (props) {
    const amendId = props.id
    const { proxy } = getCurrentInstance()
    const formRef = ref()
    const formRef1 = ref()
    const formState = reactive({
      mobile: '',
      code: '',
      user_pwd: '',
      check_pwd: '',
      id: 0
    })
    const amendCode = ref({
      flag: true,
      num: 60,
      numFlag: false,
      mobile: '',
      mobileFlag: false,
      offset: 0
    })
    let times = Number
    const checkMobile = async (rule, value) => {
      if (amendCode.value.mobileFlag) {
        amendCode.value.mobileFlag = false
        return Promise.reject(new Error('手机号未注册！'))
      }
      if (!value) {
        amendCode.value.flag = true
        return Promise.reject(new Error('请输入手机号'))
      }
      const patt = /1(3[0-9]|4[0,1,4-9]|5[0-3,5-9]|6[2,5-7]|7[0-8]|8[0-9]|9[0-3,5-9])\d{8}/
      if (patt.test(value) && value.length > 10) {
        amendCode.value.flag = false
        return Promise.resolve()
      } else {
        amendCode.value.flag = true
        return Promise.reject(new Error('请输入正确的手机号'))
      }
    }
    const user_pwd = async (rule, value) => {
      if (value === '') {
        return Promise.reject(new Error('请输入密码'))
      } else {
        if (value.length >= 5) {
          formRef.value.validateFields('checkPass')
        } else {
          return Promise.reject(new Error('请输入长度为5-14位密码'))
        }

        return Promise.resolve()
      }
    }

    const validatePass2 = async (rule, value) => {
      if (value === '') {
        return Promise.reject(new Error('请重新输入密码'))
      } else if (value !== formState.user_pwd) {
        return Promise.reject(new Error('两个输入不匹配!'))
      } else {
        return Promise.resolve()
      }
    }
    const rules = {
      mobile: [
        {
          validator: checkMobile,
          trigger: 'change'
        }
      ],
      user_pwd: [
        {
          validator: user_pwd,
          trigger: 'change'
        }
      ],
      checkPass: [
        {
          validator: validatePass2,
          trigger: 'change'
        }
      ]
    }
    const layout = {
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      }
    }

    const handleFinish = async (values) => {
      const { mobile, code } = values
      const res = await ResetPass({ mobile, sendCode: code })
      if (res.msg == '该手机号不存在') {
        message.warn('验证码错误！')
        return
      }
      formState.id = res.data.id
      amendCode.value.num = 60
      amendCode.value.flag = false
      amendCode.value.numFlag = false
      clearInterval(times)
      formState.code = ''
      amendCode.value.offset = 50
    }
    const handleFinish1 = async (values) => {
      const { id, user_pwd } = formState
      const res = await register({ id, user_pwd })
      message.success(res.msg)
      setTimeout(() => {
        if (!amendId) {
          proxy.$root.$router.push({ name: 'login' })
        } else {
          props.closeFun()
          lastStep()
        }
      }, 500)
    }
    const removes = () => {
      amendCode.value.offset = 0
      // formState.mobile = ''
      formState.code = ''
      amendCode.value.num = 60
      amendCode.value.flag = false
      amendCode.value.numFlag = false
      clearInterval(times)
      formRef1.value.resetFields()
    }

    const handleFinishFailed = (errors) => {
    }

    const checkCode = async() => {
      amendCode.value.flag = true
      amendCode.value.numFlag = true
      times = setInterval(() => {
        amendCode.value.num--
        if (amendCode.value.num == 0) {
          amendCode.value.num = 60
          amendCode.value.flag = false
          amendCode.value.numFlag = false
          clearInterval(times)
        }
      }, 1000)
      amendCode.value.mobile = formState.mobile
      const res = await regResetCode(formState.mobile)
      if (res.msg == '该手机号不存在') {
        amendCode.value.mobileFlag = true
        amendCode.value.num = 60
        amendCode.value.flag = false
        amendCode.value.numFlag = false
        clearInterval(times)
        formRef.value.validateFields('mobile')
      }
    }
    const lastStep = () => {
      amendCode.value.offset = 0
      formRef1.value.resetFields()
    }
    onMounted(() => {
      if (props.mobile) {
        formState.mobile = props.mobile
        formRef.value.validateFields('mobile')
      }
    })
    watch(
      props,
      (val) => {
        formState.mobile = val.mobile
        formRef.value.validateFields('mobile')
      }
    )
    return {
      formState,
      formRef,
      formRef1,
      rules,
      layout,
      handleFinishFailed,
      handleFinish,
      handleFinish1,
      checkCode,
      amendCode,
      lastStep,
      amendId,
      removes
    }
  }
})
</script>

<style lang="less" scoped>
.recover{
  min-width: 320px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  overflow: hidden;
}
.recover>div{
  width: 200%;
  display: flex;
  transition: all .5s;
}
.recover form{
  width: 50%;
}
</style>